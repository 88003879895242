.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__title {
    font-size: 40px;
    margin-bottom: 20px;
  }

  &__input {
    font-size: 20px;
    border: 2px solid #fff;
    border-radius: 8px;
    padding: 4px;
    transition: 100ms border ease-in;
    box-sizing: border-box;
    width: 300px;

    &:focus {
      outline: none;
      border-color: #0096d6;
    }
  }

  &__description {
    margin-top: 20px;
    font-size: 16px;
  }

  &__spinner {
    --size: 16px;
    --margin: 4px;
    display: inline-block;
    width: var(--size);
    height: var(--size);
    background-image: url("../assets/spinner.svg");
    background-size: var(--size) var(--size);
    animation: spin 1.5s linear infinite;
    margin-left: calc(-1 * var(--size) - var(--margin));
    margin-right: var(--margin);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #242629;
  font-family: sans-serif;
  color: #fff;
}

a {
  color: #0096d6;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}
